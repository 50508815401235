.login {
  display: grid;
  place-items: center;
  margin: 0 auto;
  padding: 8rem 0;

  > img {
    height: 5rem;
    margin: 1.5rem 0;
  }

  > form {
    display: flex;
    flex-direction: column;

    > input {
      width: 350px;
      height: 50px;
      font-size: 1rem;
      padding-left: 0.6rem;
      margin-bottom: 0.6rem;
      border-radius: 0.5rem;
      border: 1px solid black;
    }

    > button {
      width: 365px;
      height: 50px;
      font-size: large;
      color: #fff;
      background-color: #0074b1;
      border-radius: 0.5rem;
      border: 1px solid black;
      margin: 1rem 0;
      cursor: pointer;
    }
  }

  > p {
    span.login__register {
      margin-left: 0.2rem;
      color: #0177b7;
      cursor: pointer;
    }
  }
}
