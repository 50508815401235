.feed {
  flex: 0.6;
  margin: 0 20px;

  .feed__input-container {
    background-color: white;
    padding: 1rem 1rem 2rem;
    border-radius: 0.5rem;
    margin-bottom: 1.2rem;

    .feed__input {
      border: 1px solid lightgray;
      border-radius: 2rem;
      display: flex;
      padding: 0.6rem 0.6rem 0.6rem 1rem;
      color: gray;

      > form {
        display: flex;
        width: 100%;

        > input {
          border: none;
          flex: 1;
          margin-left: 1rem;
          outline-width: 0;
          font-weight: 600;
        }

        > button {
          display: none;
        }
      }
    }

    .feed__input-options {
      display: flex;
      justify-content: space-evenly;
    }
  }
}
