.widgets {
  position: sticky;
  top: 75px;
  flex: 0.2;
  background-color: white;
  border-radius: 10px;
  border: 1px solid lightgray;
  height: fit-content;
  padding: 5px 10px;

  .widgets__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.4rem;

    > h2 {
      font-size: 1rem;
      font-weight: 400;
    }
  }

  .widgets__article {
    display: flex;
    padding: 0.3rem;
    cursor: pointer;
    margin-bottom: 0.3rem;

    &:hover {
      background-color: whitesmoke;
    }

    .widgets__article-left {
      color: #0177b7;
      margin-right: 0.1rem;

      .MuiSvgIcon-root {
        font-size: 1rem;
      }
    }

    .widgets__article-right {
      flex: 1;

      > h4 {
        font-size: 1rem;
      }

      > p {
        font-size: 0.8rem;
        color: gray;
      }
    }
  }
}
