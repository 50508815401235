.header-option {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-left: 1rem;
  color: gray;
  cursor: pointer;

  .header-option__title {
    font-size: 0.8rem;
    font-weight: 400;

    > button {
      border: 0;
      border-radius: 0.3rem;

      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .header-option__icon {
    object-fit: fill;
    height: 1.6rem;
    width: 1.6rem;
  }

  &:hover {
    color: black;
  }
}
