.header {
  display: flex;
  background-color: white;
  justify-content: space-evenly;
  border-bottom: 0.1px solid lightgray;
  padding: 10px 0;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 999;

  .header__left {
    display: flex;

    img {
      object-fit: contain;
      height: 40px;
      margin-right: 0.5rem;
    }

    .header__search {
      padding: 10px;
      display: flex;
      align-items: center;
      border-radius: 5px;
      height: 22px;
      border-color: red;
      color: gray;
      background-color: #eef3f8;
      cursor: not-allowed;

      input {
        border: none;
        outline: none;
        background: none;
        cursor: not-allowed;
      }
    }
  }

  .header__right {
    display: flex;
  }
}
