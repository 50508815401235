.app {
  background-color: #f3f2ef;
  display: flex;
  flex-direction: column;
  align-items: center;

  .app__body {
    display: flex;
    margin: 1.2rem 1rem 0 1rem;
    max-width: 1200px;
  }
}
